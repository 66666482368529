<template>
<div class="content">
  <info-bar></info-bar>
  <!-- <div class="loader" v-if="loading"></div> -->
  <div class="card">
      <div class="info-list">
        <div :class="['info-list-entry', emptyColor(line)]" v-for="(line, index) in orderedList" :key="line.id">
          <div class="top" v-on:click="toggleExpansion(index)">
            <div class="line-logo">
              <img :src="displayLine(index)" class="transportIcon">
            </div>
            <div class="entry-content">
              <span class="label" v-if="emptyMessage(line)">{{ countMessage(line) }}</span>
              <span class="label1" v-else>{{ countMessage(line) }}</span>
            </div>
          </div>
          <div class="detail" v-show="isExpanded(index)">
            <template v-for='msgLine in line'>
              <div class="detail-entry" :key="msgLine.id">
                <img :src="displayContent(msgLine).img" :class="displayContent(msgLine).class">
                <p class="detail-entry-content">
                  <template><template><br :key="msgLine.id"></template><span :key="msgLine.id">{{ msgLine.Snippet }}.<span><b>{{ displayContent(msgLine).date }}</b></span></span></template>
                </p>
              </div>
              <div class="goto" :key="msgLine.id" v-on:click="gotoUrl(msgLine.Url)" v-if="msgLine.Author !== 'OnLineUser'"> 
                <span class="label">{{ linkInformation }}<i :class="['ic', 'fas fa-arrow-right']"></i></span>
              </div>
            </template>
          </div>       
        </div>
      </div>
  </div>
  <survey></survey>
</div>
</template>

<script>
import InfoBar from './InfoBar.vue'
import store from '../store/data'
// import { shurl } from '../config'
import moment from 'moment'
import Survey from './Survey.vue'

export default {
  data: function() {
    return {
      expandedGroup: [],
      icons: store.state.info.icons,
      lineIcons: store.state.info.lineIcons,
      texts: store.state.info.texts
    }
  },
  computed: {
    lines: function() {
      return this.$store.state.lines
    },
    loading: function() {
      return this.$store.state.loading
    },
    orderedList() {
      const line = {"S1": [], "S2": [], "S3": [], "S4": [], "S6": [], "S7": [], "S8": [], "S20": []}
      if (this.lines['S1']) line['S1'] = this.lines['S1']
      if (this.lines['S2']) line['S2'] = this.lines['S2']
      if (this.lines['S3']) line['S3'] = this.lines['S3']
      if (this.lines['S4']) line['S4'] = this.lines['S4']
      if (this.lines['S6']) line['S6'] = this.lines['S6']
      if (this.lines['S7']) line['S7'] = this.lines['S7']
      if (this.lines['S8']) line['S8'] = this.lines['S8']
      if (this.lines['S20']) line['S20'] = this.lines['S20']
      return line
    },
    linkInformation() {
      return this.texts.linkInformation
    }
  },
  mounted() {
    this.$store.dispatch('loadData', window.shurl);
    setInterval(function () {
      this.$store.dispatch('loadData', window.shurl);
    }.bind(this), 30000); 
  },
  methods: {
    numberToLetter (number) { 
      let unite
      switch (number) {
        case 1: unite = "Eine"; break;
        case 2: unite = "Zwei"; break;
        case 3: unite = "Drei"; break;
        case 4: unite = "Vier"; break;
        case 5: unite = "Fünf"; break;
        case 6: unite = "Sechs"; break;
        case 7: unite = "Sieben"; break;
        case 8: unite = "Acht"; break;
        case 9: unite = "Neun"; break;
        default: unite = "Give numbers between 1 and 9"; break;
      }
      return unite;
    },
    displayContent(msg) {
      if (msg.Type_message === 'travaux'){
        const dateFormat = moment(msg.Date).format('DD.MM.YYYY')
        const timeFormat = moment(msg.Date).format('HH:mm')
        const message = " Veröffentlicht am "+dateFormat+" um "+timeFormat+" Uhr"
        return {
          img: this.icons['bahn'].img,
          date: message,
          class: "transportIcon1"
        }
      }
      else if (msg.Type_message === 'user') {
        const timeFormat = moment(msg.Date).format('HH:mm')
        const message = " Dieser Echtzeit-Kommentar wurde über die App eingegeben. "+" Vor "+timeFormat
        return {
          img: this.icons['user-picto'].img,
          date: message,
          class: "transportIcon2"
        }
      }
      else {
        const timeFormat = moment(msg.Date).format('HH:mm')      
        const message = " Vor "+timeFormat
        return {
          img: this.icons['pic-colla'].img,
          date: message,
          class: "transportIcon2"
        }
      }
    },
    displayLine(key) {
      if(key) return this.lineIcons[key].img
    },
    isExpanded(key) {
      return this.expandedGroup.indexOf(key) !== -1
    },
    toggleExpansion(key) {
      if (this.isExpanded(key)) this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
      else this.expandedGroup.splice(0, 1, key)
    },
    iconDisplay(key) {
        if(key.length !== 0) return '$color-highlight'
        else return '$color-pale-green'
    },
    countMessage(msg) {
      let i = 0
      for(const index of msg) {
        index
        i = i + 1
      }
      if (i > 1 && i < 10) return this.numberToLetter(i)+' '+this.texts.informations
      if (i === 1) return this.numberToLetter(i)+' '+this.texts.information
      if (i === 0) return this.texts.emptyInformation
      if (i >= 10) return i+' '+this.texts.informations
    },
    emptyMessage(line) {
      if(line.length !== 0) return true
      else return false
    },
    emptyColor(line) {
      if (line.length === 0) return 'green'
    },
    gotoUrl(url) {
      window.open(url,'_blank','height=500,width=500,status=no, toolbar=no,menubar=no,location=no');
      return false
    }
  },
  components: {
    'info-bar': InfoBar,
    'survey': Survey
  },
}
</script>

<style lang="scss">
@import "../css/_var";

  .loader {
      border: 7px solid #f3f3f3; /* Light grey */
      border-top: 8px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin: 50px 43% 0 43%;
      animation: spin 6s linear infinite;
    }

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  
  .card {
    flex-grow: 0;
    flex-shrink: 0;
    padding: $card-padding;
    width: 80%;
    position: relative;
    background: $color-card;
    margin: auto;
    margin-top: 0px;
    box-shadow: $card-shadow-below;

    transition: transform 300ms;
    &:nth-child(3n+1) {
      transition-delay: 100ms;
    }
    &:nth-child(3n+2) {
      transition-delay: 200ms;
    }
    &:nth-child(3n+3) {
      transition-delay: 300ms;
    }


    &.subcard {
      margin-top: 0;
      padding: 0 $card-padding;

      &.expanded {
        padding-top: $card-padding;
        padding-bottom: $card-padding;
      }
    }

    &.fullwidth {
      padding: $card-padding 0;
    }
  }

  .info-list {
    margin: 20 (0-$dimension-b);
    
    .info-list-entry {
      .top {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: $list-entry-height;
        color: $color-neutral;
        background: $color-highlight;
        border-radius: 5px;
        box-shadow: $card-shadow-below;
        margin-bottom: 5px;
        cursor: pointer;
      }

      .entry-content {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0 1em 0 .5em;

        .label {
          font-weight: bold;
          font-size: $font-size-big;
          font-family: Arial, sans-serif !important;
        }
        .label1 {
          font-weight: bold;
          font-size: $font-size-big;
          font-family: Arial, sans-serif !important;
        }
        .summary {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-end;
          font-size: $font-size-smaller;
          white-space: nowrap;
        }
      }

      .transportIcon .icon {
        font-size: 1.2em;
      }

      .line-logo {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        .transportIcon {
          font-size: 20px;
          margin-left: 0.2em;
          border-radius: 10px;
        }
      }

      .detail {
        padding-bottom: 3px;
        transition: height 300ms;
        overflow: hidden;
        box-shadow: $card-shadow-below;


        .line:not(:last-child) {
          margin-bottom: .5em;
        }

        .detail-entry {
          padding: 8px;  
          min-height: 80px;

          .transportIcon1 {
            float: left;
            margin-right: 10px;
            border-radius: 10px;;
            width: 60px;
            height: 43px;
          }

          .transportIcon2 {
            float: left;
            margin-right: 30px;
            border-radius: 10px;;
            width: 60px;
            height: 60px;
          }          

          .detail-entry-content {
            line-height: 1em;
            font-size: 14px;
            font-family: Arial, sans-serif;
          }
        }
      }

      .goto {
        width: auto;
        padding: 14px 1em;
        cursor: pointer;
        background: #bad1ba;

        &:not(:first-child) {
          border-top: solid 1px white;
        }

        .label {
          width: 100%;
          display: inline-block;
          font-weight: bold;
          text-align: center;

          .ic {
            margin-left: 8%;
          }
        }
      }

      &.blue {
        .top {
          background: $color-blue;
        }
        .goto {
          background: mix($color-blue, white, 30);
        }
      }

      &.red {
        .top {
          background: $color-red;
        }      
        .goto {
          background: mix($color-red, white, 30);
        }
      }

      &.yellow {
        .top {
          background: $color-yellow;
        }
        .goto {
          background: mix($color-yellow, white, 30);
        }
      }

      &.green {
        .top {
          background: $color-green;
          cursor: auto;
        }
        .goto {
          background: mix($color-green, white, 30);
        }
      }
    }
  }
</style>