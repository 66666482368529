<template>
<div class="content">
  <info-bar></info-bar>
  <div class="card">
      <div class="info-list">
        <template v-if="lines == true"><template><br></template><span>No alerts</span></template>      
        <div class="info-list-entry" v-for="line in lines" :key="line.Id">
          <div class="detail">
            <template>
              <div class="detail-entry" :key="line.Date">
                <img :src="displayContent(line).img" :class="displayContent(line).class">
                <p class="detail-entry-content">
                  <input type="checkbox" :value="line.Id" :key="line.Id" class="checkBox" v-model="checkedId">
                  <template><template><br :key="line.Author"></template><span :key="line.Snippet">{{ line.Snippet }}.<span class="timeSpan"><b>{{ displayContent(line).date }}</b></span></span></template>
                </p>
              </div>
            </template>
          </div> 
        </div>
      </div>
      <input type="button" value="Reject" class="reject" v-on:click="rejectedTweet()"/> 
  </div>
</div>
</template>

<script>
import InfoBar from './InfoBar.vue'
import store from '../store/data'
// import { admin } from '../config'
import moment from 'moment'
import axios from 'axios'

export default {
  data: function() {
    return {
      icons: store.state.info.icons,
      checkedId: []
    }
  },
  computed: {
    lines: function() {
      if (this.$store.state.lines) return this.$store.state.lines
      else return true
    }
  },
  mounted() {
      this.$store.dispatch('loadData', window.adurl);
  },
  methods: {
    displayContent(msg) {
      if (msg.Type_message === 'travaux'){
        const dateFormat = moment(msg.Date).format('DD.MM.YYYY')
        const timeFormat = moment(msg.Date).format('HH:mm')
        const message = " Veröffentlicht am "+dateFormat+" um "+timeFormat+" Uhr"
        return {
          img: this.icons['bahn'].img,
          date: message,
          class: "transportIcon1"
        }
      }
      else {
        const timeFormat = moment(msg.Date).format('HH:mm')      
        const message = " Vor "+timeFormat
        return {
          img: this.icons['pic-colla'].img,
          date: message,
          class: "transportIcon2"
        }
      }
    },
    rejectedTweet(){
      if (this.checkedId.length > 0){
        this.$confirm("Are you sure ?").then(() => {
        axios.post(window.adpurl, this.checkedId,{
          headers: {
            "Content-Type": "text/plain" 
          },
        })
        .then(res => {
          if(res.status === 200){
            this.$alert("Your request has been taken into account !");
            this.checkedId.splice(0)
          }
        })
        .catch(error => console.log(error));
        })
      }
      else {
        this.$alert("Please check at least one box !");
      }
    }
  },
  components: {
    'info-bar': InfoBar,
  },
}
</script>

<style lang="scss">
@import "../css/_var";
  .reject {
      border: 0;
      line-height: 2.5;
      padding: 0 20px;
      font-size: 1rem;
      margin-top: 25px;
      text-align: center;
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      border-radius: 10px;
      width: 100px;
      background-color: rgba(220, 0, 0, 1);
      background-image: linear-gradient(to top left,
                                        rgba(0, 0, 0, .2),
                                        rgba(0, 0, 0, .2) 30%,
                                        rgba(0, 0, 0, 0));
      box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
                  inset -2px -2px 3px rgba(0, 0, 0, .6);
  }

  .reject:hover {
      background-color: $color-red;
  }

  .reject:active {
      box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
                  inset 2px 2px 3px rgba(0, 0, 0, .6);
  }

  .loader {
      border: 7px solid #f3f3f3;
      border-top: 8px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin: 50px 43% 0 43%;
      animation: spin 6s linear infinite;
    }

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  
  .card {
    flex-grow: 0;
    flex-shrink: 0;
    padding: $card-padding;
    width: 80%;
    position: relative;
    background: $color-card;
    margin: auto;
    margin-top: 0px;
    box-shadow: $card-shadow-below;

    transition: transform 300ms;
    &:nth-child(3n+1) {
      transition-delay: 100ms;
    }
    &:nth-child(3n+2) {
      transition-delay: 200ms;
    }
    &:nth-child(3n+3) {
      transition-delay: 300ms;
    }


    &.subcard {
      margin-top: 0;
      padding: 0 $card-padding;

      &.expanded {
        padding-top: $card-padding;
        padding-bottom: $card-padding;
      }
    }

    &.fullwidth {
      padding: $card-padding 0;
    }
  }

  .info-list {
    margin: 20 (0-$dimension-b);
    

    .info-list-entry {
      .top {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: $list-entry-height;
        // color: $color-neutral;
        // background: $color-highlight;
        border-radius: 5px;
        box-shadow: $card-shadow-below;
        margin-bottom: 5px;
      }

      .entry-content {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0 1em 0 .5em;

        .label {
          font-weight: bold;
          font-size: $font-size-big;
        }

        .summary {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-end;
          font-size: $font-size-smaller;
          white-space: nowrap;
        }
      }

      .transportIcon .icon {
        font-size: 1.2em;
      }

      .line-logo {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        .transportIcon {
          font-size: 20px;
          margin-left: 0.2em;
          border-radius: 10px;
        }
      }

      .detail {
        padding-bottom: 3px;
        transition: height 300ms;
        // overflow: hidden;
        border-radius: 5px;
        box-shadow: $card-shadow-below;


        .line:not(:last-child) {
          margin-bottom: .5em;
        }

        .detail-entry {
          padding: 8px;  
          min-height: 80px;

          .transportIcon1 {
            float: left;
            margin-right: 10px;
            border-radius: 10px;
            width: 60px;
            height: 43px;
          }

          .transportIcon2 {
            float: left;
            margin-right: 10px;
            border-radius: 10px;
            width: 60px;
            height: 60px;
          }        

          .checkBox {
            float: left;
            margin-right: 10px;
            margin-top: 15px;
          }

          .detail-entry-content {
            margin-top: 15px;
            line-height: 1em;
          }
        }
      }

      .goto {
        width: auto;
        padding: 14px 1em;
        cursor: pointer;
        background: #bad1ba;

        &:not(:first-child) {
          border-top: solid 1px white;
        }

        .label {
          width: 100%;
          display: inline-block;
          font-weight: bold;
          text-align: center;

          .ic {
            margin-left: 8%;
          }
        }
      }

      &.blue {
        .top {
          background: $color-blue;
        }
        .empty {
          background: $color-green;
        }
        .goto {
          background: mix($color-blue, white, 30);
        }
      }

      &.red {
        .top {
          background: $color-red;
        }      
        .goto {
          background: mix($color-red, white, 30);
        }
      }

      &.yellow {
        .top {
          background: $color-yellow;
        }
        .goto {
          background: mix($color-yellow, white, 30);
        }
      }

      &.green {
        .top {
          background: $color-green;
        }
        .goto {
          background: mix($color-green, white, 30);
        }
      }
    }
  }
</style>