<template>
  <div id="app">
    <router-view></router-view>
  </div> 
</template>

<script>

export default {
  data () {
    return{
    }
  }
}
</script>

<style lang="scss">
  .tab {
    margin-top: 60px;
  }
</style>