<template>
  <div>
    <b-modal id="modal-scoped" hide-header hide-footer size="sm">
        <template>
            <div class="survey_content">
                <span class="survey_span">Hast Du Lust, uns vier kurze Fragen zu beantworten ?</span>
                <div class="survey_button">
                    <b-button @click="cancel()" variant="outline-secondary" class="bouton1">Abbrechen</b-button>
                    <b-button @click="valid()" class="bouton2"><span class="bouton2_span">Umfrage starten</span></b-button>
                </div>
            </div>
        </template>
    </b-modal>
    <survey-question></survey-question>
  </div>
</template>

<script>
import SQuestion from './SurveyQuestion.vue'
export default {
  watch: {
  },
  data() {
    return {};
  },
  mounted() {
    if(!localStorage.getItem('modal')){
        setTimeout(() => {
            this.$root.$emit('bv::show::modal', 'modal-scoped')
        }, 15000)
    }
  },
  methods: {
      valid: function() {
        this.$bvModal.show('modal-question')
        this.$bvModal.hide('modal-scoped')
      },
      cancel: function() {
        this.$bvModal.hide('modal-scoped')
      }
  },
  components: {
      'survey-question': SQuestion  
  }
};
</script>

<style lang="scss">
@import "../css/_var";
    #modal-scoped {
        margin-top: 60px;
    }
    .survey_content {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .survey_button {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .servey_span {
        font-family: Arial, sans-serif !important;
    }
    .bouton1 {
        margin-right: 10px;
        position: relative;
    }
    .bouton2 {
        background-color: #B2C900 !important;
        border: none;
    }
    .bouton2_span {
        font-weight: bold;
        color: black;
    }
</style>
