import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const iconModule = {
    namespaced: true,
    state: {
        color: {
            'blue': '#007cb2',
            'red': '#c03030',
            'lightred': '#f07070',
            'deepred': '#900000',
            'green': '#1a661a',
            'yellow': '#fab23c',
            'purple': '#d1327d',
            'brown': '#a39477',
            'grey': '#b5b5b5',
            'dark-grey': '#3a3a3a',
            'white': 'white',
            'black': 'black',
        },
        lineIcons: {
            "S1": {
                img: require('../../static/img/München_S1.svg.png'),
                name: 'S1'
            },
            "S2": {
                img: require('../../static/img/München_S2.svg.png'),
                name: 'S2'
            },
            "S3": {
                img: require('../../static/img/München_S3.svg.png'),
                name: 'S3'
            },
            "S4": {
                img: require('../../static/img/München_S4.svg.png'),
                name: 'S4'
            },
            "S6": {
                img: require('../../static/img/München_S6.svg.png'),
                name: 'S6'
            },
            "S7": {
                img: require('../../static/img/München_S7.svg.png'),
                name: 'S7'
            },
            "S8": {
                img: require('../../static/img/München_S8.svg.png'),
                name: 'S8'
            },
            'S20': {
                img: require('../../static/img/München_S20.svg.png'),
                name: 'S20'
            },
        },
        icons: {
            'bahn': {
                img: require('../../static/img/logo-deutsche-bahn.png')
            },
            'pic-colla': {
                img: require('../../static/img/collaborative-picto.png')
            },
            'bar-logo': {
                img: require('../../static/img/3logosDB.png')
            },
            'bar-logo2': {
                img: require('../../static/img/3logosDB-v2.png')
            },
            'user-picto': {
                img: require('../../static/img/form-message-icon.png')
            }
        },
        texts: {
            'informations': 'Störungsmeldungen',
            'information': 'Störungsmeldung',
            'emptyInformation': 'Keine Störungsmeldung',
            'linkInformation': 'weiter zur Originalnachricht',
            'barInformation': 'Informationen je Linie',
            'barInformation2': 'Current Alerts',
            'feedback': 'Gefällt\'s Dir?'
        }
    }
}

export default new Vuex.Store({
    state: {
        lines: [],
        loading: true
    },
    actions: {
        loadData(context, url){
            axios.get(url, {
                headers: {
                },
                withCredentials: false,
                validateStatus: function (status) {
                    return status >= 200 && status <= 304; // default
                },
            })
            .then((response) => {
                // if (process.env.NODE_ENV === 'production') {
                //     if (response.headers['last-modified'] !== null) {
                //         axios.defaults.headers['If-Modified-Since'] = response.headers['last-modified']
                //     }
                // }
                context.commit('updateLines', response.data)
                context.commit('changeLoadingState', false)
            })
            .catch(error => {console.log(error)})
        },
    },
    mutations: {
        updateLines(state, lines) {
            state.lines = lines
        },
        changeLoadingState(state, loading) {
            state.loading = loading
        }
    },    
    modules: {
        info: iconModule,
    }
})