<template>
    <div>
        <div class="float-container" v-if="!admin">
            <div class="img">
                <a rel="noopener">
                <img :src="icons['bar-logo2'].img" class="iconImg" v-on:click="gotoUrl()"/> </a>
                <a class="hideme" target="_self" href="https://twitter.com/streckenagent_M/status/1433105947896029184" @click.stop>test1</a>
                <a class="hideme" href="https://www.google.com" target="_blank" rel="noreferrer noopener">test2</a>
                <a class="hideme" href="https://stackoverflow.com/" rel="noreferrer noopener">test3</a>                
            </div>
            <div class="popup">
                <popup></popup>
            </div>
        </div>
        <div class="float-container2" v-else>
            <a rel="noopener">
            <img :src="icons['bar-logo2'].img" class="iconImg" v-on:click="gotoUrl()"/> </a>
                <a class="hideme" target="_self" href="https://twitter.com/streckenagent_M/status/1433105947896029184" @click.stop>test1</a>
                <a class="hideme" href="https://www.google.com" target="_blank" rel="noreferrer noopener">test2</a>
                <a class="hideme" href="https://stackoverflow.com/" rel="noreferrer noopener">test3</a> 
        </div>        
        <div class="info-bar">
            <span class="p-info">{{ barInformation }}</span>
        </div>
    </div>
</template>

<script>
import store from '../store/data'
import Popup from './Popup.vue'

export default {
    data: function() {
        return {
            texts: store.state.info.texts,
            icons: store.state.info.icons,
        }
    },
    computed: {
        barInformation() {
            if(this.$router.currentRoute.name === 'admin') return this.texts.barInformation2
            else return this.texts.barInformation
        },
        admin() {
            if(this.$router.currentRoute.name === 'admin') return true
            return false
        }
    },
    components: {
        'popup': Popup
    },

    methods: {
        gotoUrl() {
            window.open("https://twitter.com/streckenagent_M/status/1433105947896029184", '_blank', 'height=500, width=500, status=no, toolbar=no, menubar=no, location=no');
        }
    }
}
</script>

<style lang="scss">
@import "../css/_var";

.info-bar {
    background-color: white;
    box-shadow: $card-shadow-below;
    width: 80%;
    height: 30px;
    margin: auto;
    margin-bottom: 9px;
    position: relative;
    text-align: center;
    .p-info {
        position: static;
        font-family: Arial, sans-serif;
        font-size: 19px;
        color: #767171;
        font-weight: bold;
    }
}

.hideme {
 color: white;
 opacity: 0;
}

body{
    margin: 0;
}


.float-container2 {
    .iconImg {
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 200px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.float-container {
    height: 120px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 0px;
    .img{
        position: absolute;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        float: left;
        width: 100%;
        .iconImg {
            width: 200px;
        }
    }
    .popup{
        float: right;
        margin-top: 30px;
        margin-right: 10%;
        position: relative;
    }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
    .float-container {
        height: 100px;
        width: 100%;
        .img{
            float: left;
            position: absolute;
            width: 50%;
            padding-left: 10%;
            .iconImg {
                width: 200px;
                padding-right: 20px;
            }
        }
        .popup{
            float: right;
            margin-top: 30px;
            margin-right: 10%;
            position: relative;
        }
    }
}
</style>