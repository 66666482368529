<template lang="html">
<div>
  <b-button v-b-modal.modal-center class="Btn"><p>Störung melden</p></b-button>
  <b-form>
    <b-modal id="modal-center" title="Störung melden" hide-footer title-tag="h3" title-class="title">
      <div class="container">
        <div class="row content">
            <div v-for="icon in iconDisplay" :key="icon.name" class="col-6 display">
                <b-form-checkbox
                  :value="icon.name"
                  :key="icon.name"
                  v-model="selected">
                  <div class="line-logo">
                    <img :src="icon.img" class="transportIcon">
                  </div>
                </b-form-checkbox>
            </div>
        </div>
        <div>
          <div class="span"><span><b>Dein Echtzeit-Kommentar</b></span></div>
          <b-form-textarea size="lg" v-model="message" class="textarea" rows="3" max-rows="6"></b-form-textarea>
        </div>
        <br>
        <div class="captcha">
          <vue-recaptcha ref="recaptcha" @verify="onVerify" :sitekey="siteKey" class="content" language="de" :loadRecaptchaScript="true">
          </vue-recaptcha>
        </div>
        <button class="btn bouton" @click="onSubmit()"><p>ABSENDEN</p></button>
      </div>
    </b-modal>
    <validation-modal></validation-modal>
  </b-form>
</div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
// import config from '../config.js'
import store from '../store/data'
import ValidationModal from './ValidationPopup'
import { uuid } from 'vue-uuid'
import moment from 'moment'
import axios from 'axios'

export default {
  data(){
    return {
      robot: false,
      lineIcons: store.state.info.lineIcons,
      icons: store.state.info.icons,
      selected: [],
      message: "",
      uuid: uuid.v1(),
    }
  },
  computed: {
    siteKey: function() {
      if (process.env.NODE_ENV === 'production') return window.siteKey
      return window.localSiteKey
    },
    iconDisplay: function() {
      const iconList = []
      for(const prop in this.lineIcons) {
        if (prop !== 'bahn') {
            iconList.push(this.lineIcons[prop])
        }
      }
      return iconList
    }
  },
  methods: {
    onVerify: function (response) {
      if (response) this.robot = true;
    },
    onSubmit: function (){
      if (this.robot && this.message) {
        const id = this.uuid;
        const message = this.message
        const today = new Date()
        const date = moment(today).format('DD-MM-YYYY HH:mm:ss')
        let data
        if (this.selected.length !== 0) {
          const lines = this.selected
          data = {
            "Id": id,
            "Snippet": message,
            "Date": date,
            "Lines": lines
          }          
        }
        else {
          data = {
            "Id": id,
            "Snippet": message,
            "Date": date,
          }
        }
        axios.post(window.postUrl, JSON.stringify(data), {
            headers: {
              'Content-Type': 'text/plain'
            }
        })
        .then(res => {
          if (res.status === 200) {
            this.$bvModal.show("modal-validation")
            this.$bvModal.hide("modal-center")
            setTimeout(function(){
              location.reload(true)
            }, 5000)
          }
        })
        .catch(error => console.log(error))
      }
      else {
        this.$alert("Bitte betätigen Sie die Schaltfläche „Ich bin kein Roboter“ und/oder füllen Sie das Feld aus.");
      }
    }
  },
  components: {
    'vue-recaptcha': VueRecaptcha,
    'validation-modal': ValidationModal
  },
}

</script>

<style lang="scss">
@import "../css/_var";
  .line-logo {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .display {
    padding-top: 15px;
  }
  .span{
    margin-top: 10px;
    font-size: 16px;
    font-family: Arial, sans-serif !important;
  }
  #modal-1 {
    font-family: Arial, sans-serif; 
    font-size: 1.5em; 
  }
  .bouton {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    background-color: #B2C900 !important;
    p {
      text-align: center;
      font-weight: bold;
      font-family: Arial, sans-serif;
      font-size: 18px;
      margin: 0px;
    }
  }
  .textarea {
    font-size: 18px !important;
    font-family: Arial, sans-serif;
  }
  .Btn {
    height: 63px;
    width: 90px;
    background: #EC0016 !important;
    border: none !important;
    box-shadow: 20px 20px 20px white;
    box-shadow:2px 2px 10px gray; 
    -moz-box-shadow:2px 2px 10px gray;
    -webkit-box-shadow:2px 2px 10px gray;
    p {
      font-size: 14px;
      font-weight: bold;
      margin-top: 3px;
      text-align: center;
      font-family: Arial, sans-serif !important;
    }
  }
  @media (max-width: 3000px) {
    .title {
      font-weight: bold;
      font-size: 25px;
      font-family: Arial, sans-serif !important;
      margin-left: 30%;
    } 
    .captcha {
      width: 100%;
      .content {
        transform:scale(0);
        -webkit-transform:scale(1.45);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;    
        margin-bottom: 44px;
      }
    }
  }
  @media (width: 768px){
      .title {
        font-weight: bold;
        font-size: 20px;
        font-family: Arial, sans-serif !important;
        margin-left: 35%;
      }
    .captcha {
      .content {
        transform:scale(1);
        -webkit-transform:scale(1.45);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;   
        margin-bottom: 40px; 
      }
    }  
  }
  @media (max-width: 500px) {
    .title {
      font-weight: bold;
      font-size: 25px;
      font-family: Arial, sans-serif !important;
      margin-left: 30%;
    }    
      .captcha {
      .content {
        transform:scale(0);
        -webkit-transform:scale(1.03);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;    
      }
    }
    .Btn {
        height: 58px;
        width: 70px;
        background-color: #EC0016 !important;
        p {
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          margin-top: 3px;
          font-family: Arial, sans-serif !important;
        }
      }    
  }
  @media (width: 414px){
      .title {
        font-weight: bold;
        font-size: 25px;
        font-family: Arial, sans-serif !important;
        margin-left: 25%;
      }    
     .captcha {
      width: 100%;
      .content {
        transform:scale(0);
        -webkit-transform:scale(1.110);
        transform-origin:0 0;
        -webkit-transform-origin:0 0; 
        margin-bottom: 15px;  
        width: 90%; 
      }
    }  
  }
  @media (width: 411px) {
    .title {
        font-weight: bold;
        font-size: 20px;
        font-family: Arial, sans-serif !important;
      }
    .captcha {
      .content {
        transform:scale(0);
        -webkit-transform:scale(1.1);
        transform-origin:0 0;
        -webkit-transform-origin:0 0; 
        margin-bottom: 10px;  
      }
    }     
  }
  @media (width: 390px){
      .title {
        font-weight: bold;
        font-size: 20px;
        font-family: Arial, sans-serif !important;
        margin-left: 20%;
      }
    .captcha {
      .content {
        transform:scale(0);
        -webkit-transform:scale(1.03);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        margin-bottom: 0px; 
        width: 100% 
      }
    }  
  }
  @media (width: 375px){
    .title {
        font-weight: bold;
        font-size: 20px;
        font-family: Arial, sans-serif !important;
        margin-left: 25%;
    }
    .captcha {
      .content {
        transform:scale(0);
        -webkit-transform:scale(0.98);
        transform-origin:0 0;
        -webkit-transform-origin:0 0; 
        margin-bottom: 0px; 
      }
    }  
  }
  @media (width: 360px){
      .title {
        font-weight: bold;
        font-size: 20px;
        font-family: Arial, sans-serif !important;
        margin-left: 25%;
      }
    .captcha {
      .content {
        transform:scale(0);
        -webkit-transform:scale(0.93);
        transform-origin:0 0;
        -webkit-transform-origin:0 0; 
        margin-bottom: 0px;  
      }
    }  
  }
  @media (width: 320px){
      .title {
        font-weight: bold;
        font-size: 20px;
        font-family: Arial, sans-serif !important;
        margin-left: 20%;
      }
    .captcha {
      .content {
        transform:scale(0);
        -webkit-transform:scale(0.8);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        margin-bottom: 0px;  
      }
    }  
  }
</style>
