<template>
    <div class="container">
        <b-modal id="modal-question" hide-footer size='lg'>
            <template>
                <b-form>
                    <b-form-group label="War die Information zu S-Bahnstörungen hilfreich für Dich ?" name="first">  
                        <b-form-radio-group
                            v-model="selected"
                            :options="options"
                            :key="options.value"
                            :state="state"
                        >
                        </b-form-radio-group>          
                    </b-form-group>
                    <b-form-group label="Hast Du schon mal selbst Posts zu S-Bahnstörungen abgesetzt ?" name="second">  
                        <b-form-radio-group
                            v-model="selected2"
                            :options="options"
                            :key="options.value"
                            :state="state2"
                        >
                        </b-form-radio-group>          
                    </b-form-group>
                    <b-form-group label='Wie wahrscheinlich ist es, dass Du zukünftig auch selbst Informationen hier postest?'>  
                        <b-form-radio-group
                            v-model="selected3"
                            :options="options2"
                            :key="options2.value"
                            :state="state3"
                        >
                        </b-form-radio-group>          
                    </b-form-group>
                    <b-form-group
                        label="Möchtest Du uns noch etwas zu dem neuen Feature „Echtzeit-Kommentare“ in der App mitteilen?"
                    >
                        <b-form-textarea 
                            v-model='text'
                            rows='3'
                            max-rows='6'
                        ></b-form-textarea>
                    </b-form-group>
                    <b-form-group>
                        <div class="captcha_survey">
                            <vue-recaptcha ref="recaptcha" @verify="onVerify" :sitekey="siteKey" class="content_survey" language="de" :loadRecaptchaScript="true">
                            </vue-recaptcha>
                        </div>
                    </b-form-group>
                    <b-button class="submit_btn" @click="onSubmit()">Absenden</b-button>
                </b-form>
            </template>
        </b-modal>
        <validation-modal></validation-modal>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
// import config from '../config.js'
import axios from 'axios'
import ValidationModal from './ValidationPopup'

export default {
  data() {
    return {
        selected: '',
        selected2: '',
        selected3: '',
        text: '',
        options: [
            { text: 'Ja', value:"Ja"},
            { text: 'Nein', value:"Nein"},
            { text: 'Weiß nicht', value:"Weiß nicht"}
        ],
        options2: [
            { text: 'Unwahrscheinlich', value:'Unwahrscheinlich'},
            { text: 'Wahrscheinlich', value:'Wahrscheinlich'},
            { text: 'Vielleicht', value:'Vielleicht'},
            { text: 'Weiß nicht', value:'Weiß nicht'},
        ],
        robot: false,
    };
  },
  computed: {
      state() {
         return Boolean(this.selected)
      },
      state2() {
          return Boolean(this.selected2)
      },
      state3() {
          return Boolean(this.selected3)
      },
      siteKey: function() {
        if (process.env.NODE_ENV === 'production') return window.siteKey
        return window.localSiteKey
      }
  },
  methods: {
    onVerify: function (response) {
      if (response) this.robot = true;
    },
    onSubmit: function() {
        if(this.selected && this.selected2 && this.selected3 && this.robot){
            const data = [this.selected, this.selected2, this.selected3, this.text]         
            axios.post(window.serveyUrl, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'text/plain'
                }
            })
            .then(res => {
                if(res.status === 200) {
                    this.$bvModal.show("modal-validation2");
                    this.$bvModal.hide("modal-question")
                    localStorage.setItem('modal', true)
                    setTimeout(function(){
                        location.reload(true)
                    }, 5000)
                }
            })
            .catch(error => console.log(error))
        }
        else {
            this.$alert("Bitte füllen Sie alle Lücken aus");
        }
    }
  },
  components: {
    'vue-recaptcha': VueRecaptcha,
    'validation-modal': ValidationModal
  }
};
</script>

<style lang="scss">
@import '../css/_var';
    body {
        font-family: Arial, sans-serif;
    }
    .submit_btn {
        background-color: #B2C900 !important;
        border: none !important;
        float: right;
        font-weight: bold !important;
        color: black !important;
        font-family: Arial, sans-serif !important;
    }
    @media (width: 375px){
        .captcha_survey {
            .content_survey {
                transform:scale(0);
                -webkit-transform:scale(1.072);
                transform-origin:0 0;
                -webkit-transform-origin:0 0; 
                margin-bottom: 4px; 
            }
        }  
    }
    @media (width: 320px){
        .captcha_survey {
            .content_survey {
                transform:scale(0);
                -webkit-transform:scale(0.89);
                transform-origin:0 0;
                -webkit-transform-origin:0 0; 
                margin-bottom: 0px; 
            }
        }  
    }
    @media (width: 360px){
        .captcha_survey {
            .content_survey {
                transform:scale(0);
                -webkit-transform:scale(1.025);
                transform-origin:0 0;
                -webkit-transform-origin:0 0; 
                margin-bottom: 0px; 
            }
        }  
    }
    @media (width: 414px){
        .captcha_survey {
            .content_survey {
                transform:scale(0);
                -webkit-transform:scale(1.201);
                transform-origin:0 0;
                -webkit-transform-origin:0 0; 
                margin-bottom: 20px; 
            }
        }  
    }
    @media (width: 411px){
        .captcha_survey {
            .content_survey {
                transform:scale(0);
                -webkit-transform:scale(1.2);
                transform-origin:0 0;
                -webkit-transform-origin:0 0; 
                margin-bottom: 20px; 
            }
        }  
    }
</style>