import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import pageNotFound from '../components/NotFound.vue'
import info2 from '../components/Info2.vue'
import tweetList from '../components/TweetList.vue'
import popup from '../components/Popup.vue'
import info from '../components/Info.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/alert',
    // component: info2
  },
  {
    path: '/alert',
    name: 'alert',
    component: info2,
  },
  {
    path: '/admin',
    name: 'admin',
    component: tweetList
  },
  {
    path: '/popup',
    name: 'popup',
    component: popup
  },
  {
    path: '/survey',
    name: 'survey',
    component: info
  },
  {
    path: '*',
    component: pageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
