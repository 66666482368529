<template lang="html">
  <div>
    <b-modal id="modal-validation" hide-footer hide centered>
      <p>Vielen Dank für dein Echtzeit-Kommentar!</p>
      <p>
        Die Informationen werden nach der Validierung an andere Reisende weitergegeben.
      </p>
      <button @click="rendering()" class="btn bouton">
        <p>Zurück zur Informationsseite</p>
      </button>
    </b-modal>
    <b-modal id="modal-validation2" hide-footer hide centered>
      <p>
        Vielen Dank für Dein Feedback Dein Echtzeit-Kommentar wird nach dem Absenden
        geprüft und dann veröffentlicht. Dies kann einen Moment dauern
      </p>
      <button @click="rendering()" class="btn bouton">
        <p>Zurück zur Informationsseite</p>
      </button>
    </b-modal>
  </div>
</template>

<script>
export default {
  watch: {},
  methods: {
    rendering() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
#modal-validation p {
  text-align: center;
  // font-weight: bold;
  font-family: Arial, sans-serif;
  font-size: 17px;
  margin: 0px;
}
.bouton {
  p {
    font-size: 16px;
  }
}
#modal-validation2 p {
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 17px;
  margin: 0px;
}
</style>
