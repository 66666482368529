<template>
    <div id="over1">
        <img src="../../static/img/logo-nextalert-r-transparent.jpg" class="iconImg1"/>
        <h1>Hey, diese Seite existiert nicht!</h1>
        <router-link v-bind:to="'/alert'" class="back">Nach Hause zurückkehren</router-link>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    #over1 .iconImg1 {
        width: 700px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }
    #over1 h1 {
        margin-left: 37%;
    }
    #over1 .back {
        margin-left: 44%;
        text-decoration: none;
    }
</style>